function FlightResultSearchFormProcess(handler) {
    var searchDetail = {};
    var $searchForm = handler.domNode.find(".flight-result-search-form");
    var $searchArea = $searchForm.parent();
    var $editSearch = handler.domNode.find('.edit-search');

    ajax({
        url: dataActive.location.marketplace + "/v1/search/info",
        method: 'GET',
        async: false,
        data: {
            sessionId: dataActive.location.query.sessionId
        },
        success: function (data) {
            searchDetail = data;
        }
    });
    $searchForm.append(view("flight/flight-result-search-form", { searchDetail: searchDetail }));
    // var startDate = handler.domNode.find('.start-date-container');
    var returnDateSec = searchDetail.tripType == 'roundTrip' ? $(view("flight/return-flight-result-search", {searchDetail: searchDetail.legs[1]})) : $(view("flight/return-flight-result-search", {searchDetail: {}}));
    
    $editSearch.click(function (e) {
        $element = $(e.target);
        if ($searchArea.hasClass('_mob-h')) {
            $searchArea.removeClass('_mob-h')
        } else {
            $searchArea.addClass('_mob-h')
        }

    });
    handler.domNode.find(".searcherIdentity").data("initial", searchDetail.searcherIdentity);
    handler.domNode.find(".flight-quantity-selector-input").data("initial", searchDetail);


    var tripTypeItems = handler.domNode.find('.trip-type>input');
    tripTypeItems.each((index, item) => {
        var startDate = handler.domNode.find('.start-date-container');
        if ($(item).val() == searchDetail.tripType) {
            $(item).attr("checked", "checked")
        }
        if (searchDetail["tripType"] == 'roundTrip') {
            $(startDate).removeClass("col-md-4").addClass("col-md-2");
            $(startDate).after(returnDateSec);
            handler.domNode.find(".return-label").show();
        }
        else if (searchDetail['tripType'] == 'multiDestination') {
            handler.domNode.find(".add-trip-button").show();
            const tripNum = handler.domNode.find(".trip-number").length;
            handler.domNode.find(".trip-number").each((i, form) => {
                $(form).find(".result-delete-trip-btn").show();
            });
            $(handler.domNode.find(".trip-number")[tripNum - 1]).find(".result-delete-trip-btn").hide();
        }
    });
    var flightClasses = handler.domNode.find('.cabin');
    flightClasses.each((index, item) => {
        if ($(item).val() == searchDetail.cabin) {
            $(item).attr("checked", "checked")
        }
    });
    var charterOnlyItems = handler.domNode.find('.charter-only');
    charterOnlyItems.each((index, item) => {
        if ($(item).val() == searchDetail.charterOnly) {
            $(item).attr("checked", "checked")
        }
    });

    // TRIP TYPE HANDLER:
    handler.domNode.find(".one-way").click(() => {
        handler.domNode.find(".custom-datepicker-wrapper").remove()
        var startDate = handler.domNode.find('.start-date-container');
        $(startDate).next().remove();
        $(startDate).removeClass("col-md-2").addClass("col-md-4");
        $(startDate).data('tripType','oneWay');
        handler.domNode.find(".return-label").hide();
        handler.domNode.find(".add-trip-button").hide();
        const additionalForm = handler.domNode.find(".trip-number");
        $(additionalForm).filter(":gt(0)").remove();
        $(additionalForm).find(".result-delete-trip-btn").hide();
        const datePicker = handler.domNode.find(".datepicker-input");
        var fliterButton = handler.domNode.find('form').closest('.theme-hero-area').next().find('#mobileFilters');
        ChooseCalendar(datePicker, fliterButton, false);
    });
    handler.domNode.find(".round-trip").click(() => {
        handler.domNode.find(".datePickerReturn").val("");
        handler.domNode.find(".add-trip-button").hide();
        const additionalForm = handler.domNode.find(".trip-number");
        $(additionalForm).filter(":gt(0)").remove();
        $(additionalForm).find(".result-delete-trip-btn").hide();
        var startDate = handler.domNode.find('.start-date-container');
        $(startDate).removeClass("col-md-4").addClass("col-md-2");
        $(startDate).data('tripType','roundTrip');
        if(handler.domNode.find(".end-date-container").length < 1) {
            $(startDate).after(returnDateSec);
            const returnDatePicker = $(returnDateSec).find(".datepicker-input");
            if($(returnDatePicker).nextAll().length >= 2) {
                $(returnDatePicker).next().remove();
            }
            var fliterButton = handler.domNode.find('form').closest('.theme-hero-area').next().find('#mobileFilters');
            ChooseCalendar(returnDatePicker, fliterButton, false);
        }
        handler.domNode.find(".return-label").show();
    });
    handler.domNode.find('.multi-destination').click(() => {
        var startDate = handler.domNode.find('.start-date-container');
        $(startDate).next().remove();
        $(startDate).removeClass("col-md-2").addClass("col-md-4");
        $(startDate).data('tripType','multiDestination');
        handler.domNode.find(".return-label").hide();
        const newTrip = $(view("flight/left-sec-result-form",{data: null, legIndex: 1, tripType: 'multiDestination'}))
        handler.domNode.find(".add-trip-button").show();
        if (handler.domNode.find(".trip-number").length < 2) {
            handler.domNode.find(".trip-number").after(newTrip);
            dynamicName(newTrip);
            const deleteBtn = $(handler.domNode.find(".trip-number")[0]).find(".result-delete-trip-btn");
            $(handler.domNode.find(".trip-number")[0]);
            $(handler.domNode.find(".trip-number")[0]);
            $(deleteBtn).show();
        }
    });
    handler.domNode.find('.open-return').click(function(e) {
        handler.domNode.find(".datePickerReturn").val("");
        handler.domNode.find(".add-trip-button").hide();
        const additionalForm = handler.domNode.find(".trip-number");
        $(additionalForm).filter(":gt(0)").remove();
        $(additionalForm).find(".result-delete-trip-btn").hide();
        var startDate = handler.domNode.find('.start-date-container');
        $(startDate).removeClass("col-md-4").addClass("col-md-2");
        $(startDate).data('tripType','openReturn');
        $(startDate).find('.datePickerDeparture').val("");
        $(startDate).after(returnDateSec);
        // if(handler.domNode.find(".end-date-container").length < 1) {
            
        //     // const returnDatePicker = $(returnDateSec).find(".datepicker-input");
        //     // if($(returnDatePicker).nextAll().length >= 2) {
        //     //     $(returnDatePicker).next().remove();
        //     // }
        // }
        handler.domNode.find(".return-label").show();
    });

    // ADD TRIP METHOD: MULTI CITY
    handler.domNode.find('.add-trip-button').click(() => {
        const trips = handler.domNode.find(".trip-number");
        const tripsNum = $(trips).length;
        const newTrip = $(view("flight/left-sec-result-form", { data: null, legIndex: tripsNum, tripType: 'multiDestination' }));
        if (tripsNum < 4) {
            $(trips[tripsNum - 1]).after(newTrip);
            dynamicName(newTrip)
            $(trips[tripsNum - 1]).find(".result-delete-trip-btn").show();
            $(newTrip).find('.shift-key-results').on("click", shiftKey)
        }
    });

    function dynamicName(newTrip) {
        handler.domNode.find('.trip-number').each((index, item) => {
            const $inputs = $(item).find('input');
            $inputs.each((i, input) => {
                const type = $(input).attr('data-type');
                if (type) {
                    $(input).attr("data-name", `legs[${index}][${type}]`);
                    if (type == 'departure' || type == "departureShamsiDate") {
                        $(input).attr("name", `legs[${index}][${type}]`);
                    }
                }
            })
        });
        customer(newTrip);
    }

    function customer(newTrip) {
        const airport = $(newTrip).find('.select-airport');
        const datepicker = $(newTrip).find('.datepicker-input');
        var fliterButton = handler.domNode.find('form').closest('.theme-hero-area').next().find('#mobileFilters');
        ChooseCalendar(datepicker, fliterButton, false);
        new CustomSelect(airport, { url: dataActive.location.marketplace + '/v1/search/airports' + '?lang=' + dataActive.location.query.lang.toLowerCase(), message: 'Search_by_city_or_airport' });
    }
    // SHIFT KEY LOGIC:
    $('.shift-key-results').each((i, key) => $(key).on("click", shiftKey))
    function shiftKey(e) {
        const shift = $(e.currentTarget);
        let origin = $(shift).parent().find('.select-airport');
        let destination = $(shift).parent().next().find('.select-airport');
        let originValue = $(origin).prev().find('input[type="hidden"]');
        let destinationValue = $(destination).prev().find("input[type='hidden']");
        let originTitle = $(origin).prev().prev();
        let destinationTitle = $(destination).prev().prev();
        // if both inputs are fill:
        if(originValue.val() !== "" && destinationValue.val() !== "") {
            // in the first step we have to store old values to access to them in the next step:
            const newOrgVal = destinationValue.val();
            const newDesVal = originValue.val();
            const newOrgTitle = destinationTitle.prev().html();
            const newDesTitle = originTitle.prev().html();
            // in this step we wanna access to old values:
            destinationValue.val(newDesVal);
            originValue.val(newOrgVal);
            destinationTitle.prev().html(newDesTitle);
            originTitle.prev().html(newOrgTitle);
        }
        // if one of the inputs is fill:
        else if (originValue.val() !== "" && destinationValue.val() === "") {
            const newDesVal = originValue.val();
            const newDesTitle = originTitle.prev().html();
            destinationValue.val(newDesVal);
            destinationTitle.html(newDesTitle).css("width","90%").after('<i class="fa fa-times clear-selected" data-title="Destination"></i>');
            originValue.val("");
            originTitle.prev().html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>'+trans("Origin")).next().remove();
        }
        else if(destinationValue.val() !== "" && originValue.val() === "") {
            const newOrgVal = destinationValue.val();
            const newOrgTitle = destinationTitle.prev().html();
            originValue.val(newOrgVal);
            originTitle.html(newOrgTitle).css("width","90%").after('<i class="fa fa-times clear-selected" data-title="Origin"></i>');
            destinationValue.val("");
            destinationTitle.prev().html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>'+trans("Destination")).next().remove();
        }
        origin.parent().find(".clear-selected").on("click",clearInput);
        destination.parent().find(".clear-selected").on("click",clearInput);
    }

    function clearInput(e) {
        $(e.currentTarget).prev().html("").html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>'+trans($(e.currentTarget).data("title")));
        $(e.currentTarget).next().find("input[type='hidden']").val('');
        $(e.currentTarget).remove();
    }
}